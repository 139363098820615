import { Box, chakra, HTMLChakraProps, Text } from '@chakra-ui/react';

// todo - replace these with logo when we actually have one
export const LogoIcon = (props: HTMLChakraProps<'svg'>) => (
    <chakra.svg
        color="accent"
        height="12"
        width="auto"
        viewBox="0 0 89 89"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.7528 14.9002C83.7459 22.6904 88 32.9894 88 44.282C88 68.5826 68.3005 88.282 44 88.282C35.6893 88.282 27.9167 85.978 21.2863 81.9737L15.3884 87.0521C14.5187 87.801 13.2784 86.7338 13.8892 85.7622L22.556 71.9745L22.5485 71.9656C22.5514 71.9678 22.5544 71.9701 22.5573 71.9724L35.1199 51.9871C35.5385 51.3211 35.0599 50.4549 34.2733 50.4549H19.8769C18.9505 50.4549 18.5222 49.304 19.2231 48.6983L60.245 13.2494C55.3897 10.7025 49.8631 9.26163 44 9.26163C24.6588 9.26163 8.97959 24.9408 8.97959 44.282C8.97959 52.5687 11.8577 60.1831 16.6689 66.1802L11.7467 74.211C4.45724 66.3591 0 55.8411 0 44.282C0 19.9815 19.6995 0.282043 44 0.282043C52.6142 0.282043 60.6502 2.75747 67.4355 7.03577L72.5813 2.58901C73.4507 1.83776 74.6934 2.9057 74.0815 3.87819L53.421 36.7143C53.002 37.3802 53.4806 38.2468 54.2674 38.2468H69.3753C70.3026 38.2468 70.7305 39.3996 70.0278 40.0046L28.5503 75.719C33.2103 78.0136 38.4546 79.3025 44 79.3025C63.3412 79.3025 79.0204 63.6233 79.0204 44.282C79.0204 36.2682 76.3286 28.883 71.7999 22.9813L76.7528 14.9002Z"
        />
    </chakra.svg>
);

type LogoProps = {
    variant?: 'full' | 'minimal';
    backgroundColor?: string;
    fill?: string;
    showText?: boolean;
};

export const ConnectAiLogo = ({
    variant = 'full',
    backgroundColor = '#ebebeb',
    fill = '#000000',
    showText = false,
}: LogoProps) => {
    const isMinimal = variant === 'minimal';
    const wrapperStyles = isMinimal
        ? {
              display: 'flex',
              justifyContent: 'center',
          }
        : { display: 'flex', alignItems: 'center' };

    return (
        <div style={wrapperStyles}>
            <Box
                backgroundColor={backgroundColor}
                style={{
                    display: 'inline-block',
                    marginRight: isMinimal ? '0' : '16px',
                    borderRadius: '4px',
                }}
            >
                <svg width="48" height="48" viewBox="-12 -12 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.895 3.553C17.8119 3.38684 17.6841 3.24711 17.526 3.14944C17.368 3.05177 17.1858 3.00003 17 3H7.00001C6.62101 3 6.27501 3.214 6.10501 3.553L2.10501 11.553C2.03549 11.6919 1.9993 11.8451 1.9993 12.0005C1.9993 12.1559 2.03549 12.3091 2.10501 12.448L6.10501 20.448C6.27501 20.786 6.62101 21 7.00001 21H17C17.379 21 17.725 20.786 17.895 20.447L21.895 12.447C21.9645 12.3081 22.0007 12.1549 22.0007 11.9995C22.0007 11.8441 21.9645 11.6909 21.895 11.552L17.895 3.553ZM19.382 11H11.618L8.61801 5H16.382L19.382 11ZM4.11801 12L7.00001 6.236L9.88201 12L7.00001 17.764L4.11801 12ZM16.382 19H8.61801L11.618 13H19.382L16.382 19Z"
                        fill={fill}
                    />
                </svg>
            </Box>
            {showText && variant === 'full' && (
                <Text fontSize="xl" fontWeight="bold">
                    ConnectAI
                </Text>
            )}
        </div>
    );
};
