'use client';

import { Button } from '@/components/ui/button';
import { Password, Username } from '@app/auth/_common/Auth';
import { useValidPassword, useValidUsername } from '@app/auth/_common/hooks/useAuthHooks';
import { ROUTES } from '@app/routes';
import { Flex, Heading, Stack, StackProps, Text } from '@chakra-ui/react';
import useCustomToast from '@common/Alerts/CustomToast';
import { CtForm } from '@common/CtForm';
import { useRunOnce } from '@common/hooks/useRunOnce';
import { LogoIcon } from '@common/Logo';
import { signIn } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import * as React from 'react';

export const SignInForm = (props: StackProps) => {
    const { username, setUsername, usernameIsValid } = useValidUsername('');
    const { password, setPassword, passwordIsValid } = useValidPassword('');
    const [, setError] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const toast = useCustomToast();

    const search = useSearchParams();
    const error = search.get('error');

    useRunOnce(() => {
        setTimeout(() => {
            if (error) {
                toast({
                    title: 'Error',
                    description: error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }, 500);
    });

    const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0;

    const router = useRouter();

    const handleSubmitLoginForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            await signIn('credentials', {
                username: username,
                password,
                signinUrl: ROUTES.AUTH.LOGIN,
                callbackUrl: ROUTES.DASHBOARD.INDEX,
            });
        } catch (err) {
            setError((err as Error).message);
            toast({
                title: 'Sign In Failed',
                description: (err as Error).message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const passwordResetClicked = () => {
        router.push(ROUTES.AUTH.REQUEST_CODE);
    };

    return (
        <Flex direction="column" gap="2rem" {...props}>
            <Stack gap="6">
                <LogoIcon display={{ md: 'none' }} />
                <Stack gap={{ base: '2', md: '3' }} textAlign="left">
                    <Heading size={{ base: 'sm', md: 'md' }}>Login</Heading>
                    <Text color="fg.muted">Welcome back! Please login.</Text>
                </Stack>
            </Stack>
            <CtForm onSubmit={handleSubmitLoginForm}>
                <Flex direction="column" gap="1.5rem">
                    <Flex direction="column" gap="0.5rem">
                        <Flex direction="column" gap="1.25rem">
                            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
                            <Password passwordIsValid={passwordIsValid} setPassword={setPassword} />
                        </Flex>
                        <Button variant="plain" size="sm" onClick={passwordResetClicked}>
                            Forgot password
                        </Button>
                    </Flex>
                    <Button type="submit" disabled={isValid} loading={isSubmitting} loadingText="Signing in">
                        Sign in
                    </Button>
                </Flex>
            </CtForm>
        </Flex>
    );
};
